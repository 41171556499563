@import '../../../../resources/styles/variables.scss';
.grid {
    overflow: hidden;
    border-radius: 3px;
    width: 100%;
    transition: transform .3s ease-in 0s;
    background-color: #fff;
    height: 100%;
}


.sidebar {
    position: relative;
    height: 100%;
    width: 328px;
    float: left;
    background-color: #f5f5f5;
    padding: 15px 22px 0;
    border-right: 1px solid #c3c3c3;
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
}

.stage {
    width: 280px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    margin-bottom: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: black;
    &:hover {
        background-color: #dfe0e282;
    }
    &_active {
        background-color: #d4d5d7;
    }

    &__frame {
        height: 30px;
        width: 30px;
        border-radius: 3px;
        border: 1px solid $darkBlue;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        margin-left: 5px;
    }

    &__title {
        font-family: Circe;
    }
}