@import '../../../../../../resources/styles/variables.scss';
.grid {
    padding: 14px 68px 10px 36px;
    box-sizing: border-box;
    overflow: hidden;
}

.wrap {
    overflow-x: scroll;
    display: flex;
}

.step {
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 2px;
    position: relative;
    user-select: none;
    width: 268px;
    position: relative;
    border: 1px solid #d2d2d2;
    margin-right: 10px;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
        background-color: #F6F9FB;
        & .remove {
            display: block;
        }
    }
}

.head {
    height: 45px;
    width: 100%;
    display: flex;
    background-color: $lightBlue;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    &__empty {
        width: 26px;
    }
}

.name {
    font-family: Circe;
    text-transform: uppercase;
    border: none;
    text-align: center;
    outline: none;
    font-size: 18px;
    width: 173px;
}

.icon {
    width: 26px;
    height: 26px;
}

.img {
    display: none;
    &_active {
        display: flex;
    }
}

.container {
    padding: 15px 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lifter {
    margin: 15px 0;
}

.boards {
    width: 100%;
    position: relative;
    &:hover{
        background-color: #8DA7B610;
        & .diagram__box {
            display: block;
        }
    }
    &__title {
        width: 254px;
        height: 40px;
        padding-left: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        text-transform: uppercase;
        font-family: Circe;
        border-radius: 4px;

        &_active {
            background-color: #8da7b666;
        }
    }
}


.diagram {
    position: relative;
    margin-bottom: 40px;
    z-index: 10000;

    &__title {
        font-family: Circe;
        margin-bottom: 15px;
        text-align: center;
        font-size: 18px; 
    }
   
    &__box {
        display: none;
        position: absolute;
        width: 575px;
        top: 20px;
        left: 90%;
        height: 325px;
        z-index: 1;
        width: 575px;
        background-image: linear-gradient(180deg,#4d6bb2,#4d74c0 4%,#4d78c6 9%,#4c76c4 17%,#4a74c0 23%,#335496 60%,#00285e);
        border-radius: 4px;
        padding-top: 4px;
        box-sizing: border-box;
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__left {
        display: flex;
        margin-left: 10px;
    }

    &__img {
        width: 14px;
        height: 14px;
        border: 1px solid white;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;

        & svg {
            width: 8px;
            height: 8px;
            & path {
                fill: white;
            }
        }
    }

    &__lk {
        transform: scale(0.6);
        width: 20px;
        height: 20px;
        & path {
            fill: white;
        }
    }

    &__container {
        display: flex;
        margin: 6px 7px 0;
    }

    &__empty {
        border: 1px dotted white;
        width: 105px;
        height: 285px;
        flex-shrink: 0;
        margin-left: 5px;
    }

    &__stage {
        width: 105px;
        height: 285px;
        background-color: #fff;
        border-radius: 3px;
        flex-shrink: 0;
        margin-left: 5px;
        cursor: pointer;
        flex-direction: column;
        align-items: center;
        display: flex;
        &:hover {
            & .diagram__bigTitle{
                display: flex;
            }
            & .diagram__btn{
                opacity: 0.5;
            }
        }
        &_active {
            & .diagram__btn{
                opacity: 1;
            }
            & .diagram__bigTitle{
                display: flex;
            }
        }
    }

    &__head {
        display: flex;
        border-radius: 3px 3px 0 0;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 16px;
        background-color: #e0eeff;
    }

    &__subtitle {
        font-size: 8px;
        font-family: Circe;
        text-transform: uppercase;
    }

    &__wrap {
        height: 269px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 11px;
        box-sizing: border-box;
    }

    &__btn {
        width: 87px;
        height: 18px;
        border-radius: 2px;
        background-color: #899fcc33;
        display: flex;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
    }

    &__delete {
        display: none;
    }

    &__bigTitle {
        transform: rotate(-90deg) translate(-165px);
        font-size: 20px;
        color: #4f94cd;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        display: none;
        font-family: Circe;
        white-space: nowrap;
        &_active {
            display: flex;
        }
    }
}

.preview {
    position: relative;

    &__title {
        font-family: Circe;
        margin-bottom: 5px;
        text-align: center;
        font-size: 18px; 
        text-transform: uppercase;
        color: #4f94cd;
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__box {
        display: block;
        width: 260px;
        height: 155px;
        z-index: 1;
        background-image: linear-gradient(180deg,#4d6bb2,#4d74c0 4%,#4d78c6 9%,#4c76c4 17%,#4a74c0 23%,#335496 60%,#00285e);
        border-radius: 4px;
        padding-top: 4px;
        box-sizing: border-box;
    }

    &__left {
        display: flex;
        transform: scale(0.5);
    }

    &__img {
        width: 14px;
        height: 14px;
        border: 1px solid white;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;

        & svg {
            width: 8px;
            height: 8px;
            & path {
                fill: white;
            }
        }
    }

    &__lk {
        transform: scale(0.5);
        width: 20px;
        height: 20px;
        & path {
            fill: white;
        }
    }

    &__container {
        display: flex;
    }

    &__stage {
        width: 48px;
        height: 128px;
        background-color: #fff;
        border-radius: 3px;
        flex-shrink: 0;
        margin-left: 2px;
        cursor: pointer;
        flex-direction: column;
        align-items: center;
        display: flex;
        &:hover {
            & .diagram__bigTitle{
                display: flex;
            }
            & .diagram__btn{
                opacity: 0.5;
            }
        }
    }

    &__head {
        display: flex;
        border-radius: 3px 3px 0 0;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 16px;
        background-color: #e0eeff;
    }

    &__subtitle {
        font-size: 5px;
        font-family: Circe;
        text-transform: uppercase;
    }
}

.remove {
    display: none;
    position: absolute;
    bottom: 16px;
    left: 25px;
    text-align: center;
    height: 44px;
    line-height: 44px;
    cursor: pointer;
    border-radius: 4px;
    color: #ff4040;
    width: 220px;
    font-family: Circe;
    font-size: 18px;
    &:hover {
        background-color: #ff4040;
        color: white;
    }

}