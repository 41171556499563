
@font-face {
    font-family: "Museo500";
    src: url("./../fonts/MuseoSansCyrl_500.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Museo700";
    src: url("./../fonts/MuseoSansCyrl_700.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('./../fonts/CirceWeb-Light.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circe_Bold';
    src: url('./../fonts/CirceWeb-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFUR";
    src: url("./../fonts/SFUIText-Regular.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SFUM";
    src: url("./../fonts/SFUIText-Medium.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
}