.grid {
    width: 334px;
    height: 38px;
    background-color: #CF656D;
    position: fixed;
    bottom: 100px;
    left: calc(50% - 167px);
    border-radius: 5px;
    color: white;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

.green {
    background-color: #669C6C;
}