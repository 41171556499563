@import '../../../../../../../../resources/styles/variables.scss';

.modal {
  box-sizing: border-box;
  width: 592px;
  height: 593px;
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  outline: none;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.22);
  z-index: 1100;
}

.head {
  height: 26px;
  margin-bottom: 28px;
  display: flex;
  align-items: center;

  &__order {
    position: absolute;
    left: 20px;
    font-size: 15px;
    color: $blue;
  }

  &__title {
    font-size: 22px;
  }
}

.body {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: scroll;
}

.quantity {
  padding-bottom: 17px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $blue;

  &__packs {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__title {
    font-family: SFUM;
    font-size: 32px;
    line-height: 27px;
  }

  &__box{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  &__number {
    color: #414a55;
    font-size: 20px;
  }

  &__check {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    svg {
      transform: scale(2);
      path:first-child {
        fill: #ddd;
      }
    }
  }
}

.product {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  &__name {
      font-family: SFUM;
      font-size: 30px;
      line-height: 27px;
      margin-bottom: 5px;
  }
  &__img {
      height: 8px;
      width: 68px;
      border-radius: 2px;
  }
}


.qr {
  position: relative;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  margin: 16px 0;
  width: 100%;
  height: 126px;
  border-radius: 4px;
}

.line {
  height: 36px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.name {
  width: 160px;
  font-size: 14px;
}

.input {
  background-color: #F5F5F5;
  height: 36px;
  border: none;
  border-radius: 2px;
  flex: 1;
  padding-left: 12px;
  box-sizing: border-box;
}

.scanned {
  color: $blue;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &::before {
    display: block;
    margin-right: 5px;
    width: 330px;
    height: 1px;
    content: '';
    background-color: $blue;
  }
}

.wrapper {
  width: 100%;
  height: 380px;
  overflow-y: scroll;
  font-size: 14px;
}

.material {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 0 0 100px;
  overflow: hidden;
}

.code {
  white-space: nowrap;
  
  &:before {
    margin: 0 25px;
    content: '. . . . . . . . . . . . . . . . .';
    white-space: nowrap;
  }
}

.unknown {
  color: #a2abb6;
}

.btn {
  cursor: pointer;
  margin-left: 15px;
  transition: 0.2s;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #DDDDDD;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
      transform: scale(1.05);
  }
  &_active {
          background-color: $green;
  }
}