@import '../../../../../../resources/styles/variables.scss';

.grid {
    padding: 14px 68px 10px 36px;
    box-sizing: border-box;
    overflow: hidden;
}

.wrap {
    width: 960px;
}

.user {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    &__logo {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 13px;
        object-fit: cover;
    }
    &__wrap {
        border-radius: 3px;
        height: 44px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        &:hover {
            background-color: #f0f0f0;
        }
    }
    &__name {
        padding-left: 23px;
        box-sizing: border-box;
    }
}

.module {
    display: flex;
    align-items: center;
    padding-right: 23px;
    box-sizing: border-box;
    &__item {
        width: 30px;
        cursor: pointer;
        margin-left: 24px;
        height: 30px;
        border-radius: 3px;
        border: 1px solid $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.4;
        &_active {
            opacity: 1;
        }
        &:hover {
            opacity: 0.7;
        }
    }
    &__img {
        max-width: 23px;
        max-height: 23px;
    }

    & svg {
        & path {
            fill: $blue;
        }
    }
}
