@import '../../../../../../../../resources/styles/variables.scss';
.modal {
    width: 592px;
    height: 500px;
    background-color: #fff;
	position: fixed;
	top: calc(50% + 47px);
	left: 50%;
	display: flex;
    flex-direction: column;
	transform: translate(-50%, -50%);
    outline: none;
    border-radius: 6px;
    padding: 20px 40px;
    box-sizing: border-box;
    position: relative;
}

.overlay {
    position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
}

.title {
    display: flex;
    margin-bottom: 10px;
}

.list {
    position: relative;
    overflow: visible;
    cursor: pointer;
    text-transform: uppercase;
    margin: 10px 0;
    list-style-type: none;

    &::before {
        content: "";
        position: absolute;
        top: 45%;
        left: -20px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #4d6bb2;
        transform: translateY(-50%);
    }

    &:hover {
        
        .preview {
            display: flex;
        }
    }
}

.preview {
    background-color: #0946b6;
    position: absolute;
    top: 0;
    right: -150px;
    display: none;
    width: 475px;
    height: 315px;
    border-radius: 4px;
    z-index: 100;
    box-shadow: 0 0 8px 1px #b9b9b985;
    padding: 10px 10px;
    overflow-x: scroll;
    box-sizing: border-box;
}

.column {
    min-width: 109px;
    height: 100%;
    background-color: #fff;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &:hover {
        .column__title {
            display: block;
        }
    }

    &__head {
        background-color: #e0eeff;
        width: 100%;
        height: 30px;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        border-radius: 4px 4px 0 0;
    }

    &__body {
        display: flex;
        justify-content: center;
        height: 100%;
        position: relative;
    }

    &__title {
        display: none;
        transform: rotate(-90deg);
        position: absolute;
        bottom: 60px;
        text-transform: uppercase;
        color: #4f94cd;
    }
}