@import '../../resources/styles/variables.scss';
.grid {
    overflow: hidden;
    border-radius: 3px;
    width: 100%;
    transition: transform .3s ease-in 0s;
    background-color: #fff;
    height: 100%;
}

.sidebar {
    position: relative;
    height: 100%;
    width: 328px;
    float: left;
    background-color: #f5f5f5;
    padding: 15px 22px 0;
    overflow: auto;
    border-right: 1px solid #c3c3c3;
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
}

.member {
    position: relative;
    padding: 3px;
    cursor: pointer;
    color: #234260;
    
    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,.5);
        border-radius: 3px;
        font-size: 14px;
        margin: 0;
        padding-left: 8px;
        color: #4a4a4a;
        background-color: #fff;
        height: 50px;
        margin-bottom: 8px;
        transition: 0.2s;

        & path {
            fill: #515151;
        }

        &:hover {
            transform: scale(1.01);
        }

        &_active {
            background-color: #d8d8d8;
            img {
                opacity: 0;
            }
        }
    }
}

.title {
    margin-left: 10px;
}

.img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #d8d8d8;
    object-fit: cover;
}