.grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.login {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    justify-content: center;
}

.logo {
    width: 330px;
    height: auto;
    margin-bottom: 35px;
}

.input {
    width: 100%;
    display: block;
    padding-top: 5px;
    height: 40px;
    outline: none;
    border: none;
    color: #3eb3e4;
    text-align: left;
    letter-spacing: 1px;
    font-size: 18px;
    background: transparent;
    border-bottom: 1px solid;
    margin-top: 5px;
}

.login input::placeholder {
    color: white;
  }

  .forgot {
    display: flex;
    margin-top: 20px;
    align-self: baseline;
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-family: Circe;
  }

  .wrap {
      margin: 5px 0;
      width: 100%;
  }