@import '../../../../resources/styles/variables.scss';

.grid {
    height: 55px;
    padding: 0 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrap {
    width: 300px;
    display: flex;
    padding-left: 17px;
    box-sizing: border-box;
}

.overlay {
    position: absolute;
    width: calc(100% - 300px);
    height: calc(100% - 55px);
    top: 55px;
    left: 300px;
    z-index: 1000;
}

.title {
    color: white;
    text-transform: uppercase;
    font-family: Circe;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    font-size: 22px;
}

.wrapper {
    display: flex;
    position: relative;
}


.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    margin-right: 20px;
    box-sizing: border-box;
    z-index: 1000;
    &:hover {
        background-color: #fff;

        .btn__menu path{
            fill: #899fcc;
        }

        .btn__add path {
            fill: #899fcc;
        } 
    }

    &__menu {
        width: 20px;
        height: 15px;
        & path {
            fill: white;
        }
    }

    &__add {
        width: 13px;
        height: 12px;
        & path {
            fill: white;
        }
    }
}

.name {
    text-align: right;
    cursor: pointer;
    margin-right: 10px;
    color: white;
}

.user {
    width: 18px;
    height: 18px;
    & path {
        fill: white;
    }
}

.lk {
    border-radius: 3px;
    position: absolute;
    width: 220px;
    padding: 8px;
    background-color: #fff;
    top: 30px;
    right: 6px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &__item {
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 3px;
        text-decoration: none;
        color: black;
        &:hover {
            background-color: $blue;
            color: white;

            & .lk__ico path {
                stroke: white;
            }

            & .lk__img path {
                fill: white;
            }
        }
    }

    &__title {
        margin-left: 15px;
        text-decoration: none;
    }

    &__img {
        margin-left: 18px;
    }

    &__ico {
        margin-left: 15px;
    }

    &:after {
        content: "";
        width: 18px;
        height: 18px;
        background-color: #ffffff;
        transform: rotate(45deg);
        position: absolute;
        top: -7px;
        right: 27px;
        z-index: -1;
    }
}

.overlayLk {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
}



