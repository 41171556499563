@import '../../../../../../resources/styles/variables.scss';
.grid {
    padding: 14px 68px 10px 36px;
    box-sizing: border-box;
    overflow: hidden;
}

.wrap {
    overflow-x: scroll;
    display: flex;
}
.step {
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 2px;
    position: relative;
    user-select: none;
    width: 268px;
    overflow-y: scroll;
    border: 1px solid #d2d2d2;
    margin-right: 10px;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
        background-color: #F6F9FB;
    }
}

.head {
    height: 45px;
    width: 100%;
    display: flex;
    background-color: $lightBlue;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    &__empty {
        width: 26px;
    }
}

.name {
    font-family: Circe;
    text-transform: uppercase;
    border: none;
    text-align: center;
    outline: none;
    font-size: 18px;
    width: 173px;
}

.icon {
    width: 26px;
    height: 26px;
}

.img {
    display: none;
    &_active {
        display: flex;
    }
}

.container {
    padding: 6px 10px 0;
    box-sizing: border-box;
}

.ways {
    padding-left: 30px;
    box-sizing: border-box;
    height: 32px;
    margin-top: 8px;
    line-height: 32px;
    font-family: 'Circe';
    font-size: 18px;
    font-weight: 300;
}

.wrapper {
    display: flex;
    align-items: center;
}

.blueArrow {
    height: 40px;
    margin: 0 40px;
}

.overlay {
    position: fixed;
    width: calc(100% - 351px);
    left: 351px;
    height: calc(100% - 55px);
    top: 55px;
    display: flex;
    align-items: center;
}

.diagram {
    position: relative;
    margin-bottom: 40px;
    z-index: 10000;

    &__overlay {
        overflow-x: scroll;
        width: 100%;
        display: flex;
    }

    &__title {
        font-family: Circe;
        margin-bottom: 15px;
        text-align: center;
        font-size: 18px;
    }
   
    &__box {
        position: relative;
        width: 575px;
        height: 325px;
        background-image: linear-gradient(180deg,#4d6bb2,#4d74c0 4%,#4d78c6 9%,#4c76c4 17%,#4a74c0 23%,#335496 60%,#00285e);
        border-radius: 4px;
        padding-top: 4px;
        box-sizing: border-box;
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__left {
        display: flex;
        margin-left: 10px;
    }

    &__img {
        width: 14px;
        height: 14px;
        border: 1px solid white;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;

        & svg {
            width: 8px;
            height: 8px;
            & path {
                fill: white;
            }
        }
    }

    &__lk {
        transform: scale(0.6);
        width: 20px;
        height: 20px;
        & path {
            fill: white;
        }
    }

    &__container {
        display: flex;
        margin: 6px 7px 0;
    }

    &__empty {
        border: 1px dotted white;
        width: 105px;
        height: 285px;
        flex-shrink: 0;
        margin-left: 5px;
    }

    &__stage {
        width: 105px;
        height: 285px;
        background-color: #fff;
        border-radius: 3px;
        flex-shrink: 0;
        margin-left: 5px;
        cursor: pointer;
        &__active {

        }
        &:hover {
            & .diagram__bigTitle{
                display: flex;
            }
            & .diagram__btn {
                opacity: 0.5;
                box-shadow: none;
            }

            & .diagram__btn_active {
                opacity: 1;
                box-shadow: 0 1px 2px 0 #00000080;
                & .diagram__delete {
                    display: flex;
                }
            }

        }
    }

    &__head {
        display: flex;
        border-radius: 3px 3px 0 0;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 16px;
        background-color: #e0eeff;
    }

    &__subtitle {
        font-size: 8px;
        font-family: Circe;
        text-transform: uppercase;
    }

    &__wrap {
        height: 269px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 11px;
        box-sizing: border-box;
    }

    &__btn {
        width: 87px;
        height: 18px;
        border-radius: 2px;
        background-color: #899fcc33;
        display: flex;
        box-shadow: 0 1px 2px 0 #00000080;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &_active {
            opacity: 1;
            box-shadow: 0 1px 2px 0 #00000080;
        }
    }

    &__delete {
        display: none;
    }

    &__bigTitle {
        transform: rotate(-90deg) translate(50px);
        font-size: 20px;
        color: #4f94cd;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        display: none;
        font-family: Circe;
        white-space: nowrap;
        &_active {
            display: flex;
        }
    }
}

.card {
    width: 100%;
    height: 42px;
    border-radius: 5px;
    background-color: rgba(137,159,204,.2);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
}