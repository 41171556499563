
.text {
    flex-grow: 1;
    height: 100%;
    margin: 0 18px;
    font-size: 15px;
    border: none;
    resize: none;
    outline: none;
    line-height: 21px;
    white-space: pre-wrap;
    font-family: Museo500;
    box-sizing: border-box;
    overflow-y: auto;
}