@import '../../../../resources/styles/variables.scss';
.modal {
    width: 592px;
	position: fixed;
	top: 50%;
	left: 50%;
	display: flex;
    flex-direction: column;
	transform: translate(-50%, -50%);
    box-sizing: border-box;
    outline: none;
    align-items: center;
}

.overlay {
    position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.22);
	z-index: 1000;
}

.line {
    height: 45px;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: $blue;
    position: relative;
    &__status {
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 45px;
        border-radius: 6px 0 0 6px;
    }
    &__title {
        margin-left: 15px;
    }
}

.statusLine {
    height: 45px;
    width: 6px;
    border-radius: 6px 0 0 6px;
    margin-right: 15px;
}

.container {
    display: flex;
    height: 48px;
    width: 100%;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
    &__wrap {
        display: flex;
    }
    &__btn {
        position: relative;
        width: 22px;
        height: 22px;
        cursor: pointer;
        background-image: url('../../../../resources/img/colorBtn.svg');
        &:hover {
            background-image: url('../../../../resources/img/colorBtnBold.svg');
            width: 22px;
            height: 22px;
            background-size: contain;
            background-repeat: no-repeat;
            transform: rotate(45deg);
          
        }
        &_active {
            &:before{
                content: '';
                height: 10px;
                width: 10px;
                background-color: #fff;
                position: absolute;
                right: 6px;
                bottom: -19px;
                transform: rotate(51deg) skew(18deg, 5deg);
                z-index: 10;
                border-radius: 2px;
            }
            &:hover {
                transform: rotate(0);
            }
        }
    }
}

.module {
    width: 28px;
    height: 28px;
    border-radius: 3px;
    border: 1px solid white;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &_active {
        border: none;
        &:before{
            content: '';
            height: 10px;
            width: 10px;
            background-color: #fff;
            position: absolute;
            right: 9px;
            bottom: -16px;
            transform: rotate(51deg) skew(18deg, 5deg);
            z-index: 10;
            border-radius: 2px;
        }
    }

    & svg {
        
        height: 20px;
        & path {
            fill: white;
        }
    }
}

.wrap {
    height: 500px;
    &_active {
        width: 100%;
    }
}