@import '../../../../../../../../resources/styles/variables.scss';
.modal {
    width: 592px;
    height: 72px;
    background-color: #fff;
	position: fixed;
	top: calc(50% - 168px);
	left: 50%;
	display: flex;
    flex-direction: column;
    align-items: center;
	transform: translate(-50%, -50%);
    outline: none;
    align-items: center;
    border-radius: 4px;
    padding: 21px 20px;
    box-sizing: border-box;
    position: relative;
}

.overlay {
    position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
}

.grid {
    width: 472px;
    display: flex;
}


.color {
    height: 30px;
    opacity: 0.6;
    margin-left: 2px;
    flex: 1 1 auto;
    flex-basis: 30px;
    cursor: pointer;
    transition: 0.4s;
    &:nth-child(1){
        background-color: #899fcc;
        border-radius: 3px 0 0 3px;
        margin-left: 0;
    }
    &:nth-child(2){
        background-color: #77b8d9;
    }
    &:nth-child(3){
        background-color: #75cc95;
    }
    &:nth-child(4){
        background-color: #f5c43f;
    }
    &:nth-child(5){
        background-color: #fd8f63;
    }
    &:nth-child(6){
        background-color: #f24841;
        border-radius: 0 3px 3px 0;
    }

    &:hover {
        flex-basis: 157px;
    }
}