@import './fonts.scss';

body {
	font-family: Museo500;
	min-height: 100vh;
    position: relative;
    background-image: linear-gradient(180deg,#4d6bb2,#4d74c0 4%,#4d78c6 9%,#4c76c4 17%,#4a74c0 23%,#335496 60%,#00285e);
    //background: linear-gradient(180deg, rgba(248,179,77,1) 0%, rgba(96,59,4,1) 100%);
    overflow: hidden;
    padding: 0;
    margin: 0;
    color: #000000;
}

* {
	box-sizing: unset;
}