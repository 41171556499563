@import '../../../../resources/styles/variables.scss';
.field {
  position: relative;
  width: 100%;
    &:focus {
      box-shadow: 0 0 10px 0 blue;
    }

  &__input {
    width: 100%;
    display: block;
    height: 40px;
    outline: none;
    border: none;
    color: #3eb3e4;
    text-align: left;
    letter-spacing: 1px;
    font-size: 18px;
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    outline: none;
    font-family: Circe;

    &_password[type=text] {
      font-family: monospace;
    }
  }

  &__icon {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 13px;
    width: 30px;
    height: 14px;
  }
}
