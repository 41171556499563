.card {
    position: relative;
    height: 50px;
    padding: 3px 15px;
    border-radius: 5px;
    margin-bottom: 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // pointer-events: none;
    &__bcg {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: #efefef;
        z-index: 1;
        border-radius: 5px;
    }
}