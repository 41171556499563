.main {
    position: absolute;
    height: 100%;
    transition: transform .3s ease-in 0s;
    padding: 0 23px;
    z-index: 999;
    width: 100%;
    box-sizing: border-box;
}

.menuOpen {
    transform: translateX(278px);
}