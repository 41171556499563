@import "../../../../../../resources/styles/variables.scss";
.grid {
  padding: 25px 20px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  display: grid;
  row-gap: 20px;
  overflow-y: scroll;

  textarea {
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    outline: none;
  }
}

.products {
  display: grid;
  row-gap: 20px;
}
