.grid {
    height: calc(100% - 95px);
    top: 68px;
    overflow: auto;
    width: 250px;
    position: fixed;
    z-index: 519;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    margin-left: 35px;
    margin-right: 21px;
    opacity: 0;
    transition: transform .3s ease-in 0s,opacity .3s ease-in 0s;
    &_active {
        opacity: 1;
    }
}

.topWrap {
    display: flex;
    flex-direction: column;
}

.bottomWrap {
    display: flex;
    flex-direction: column;
}

.item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: 0;
    position: relative;
    padding: 0;
    margin-top: 5px;
    overflow: hidden;
    opacity: .7;
    color: #fff;
    height: 40px;
    border-radius: 3px;
    text-decoration: none;
    &:hover {
        background-color: rgba(0,0,0,.05);
        opacity: 1;
    }
    &_active {
        background-color: #0946b6;
    }

    & span {
        display: flex;
        font-size: 16px;
        width: auto;
        text-align: center;
        line-height: 40px;
        white-space: nowrap;
    }
}


.icon {
    width: 24px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    margin-left: 7px;
}