// @import '../../../../resources/styles/variables.scss';

.wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: transparent;
}


.leftSide {
    height: 100%;
    width: 300px;
    background-color: white;
    border-radius: 4px;
    margin-right: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 15;
    overflow-x: hidden;
    width: 400px;
}

.rightSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 370px;
    background-color: white;
    border-radius: 4px;
    flex-shrink: 0;
    position: relative;

    &__wrapText {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__data {
        text-align: center;
        font-size: 14px;
        color: #b9b9b9;
        margin: 12px 0;
    }
    
    &__addFile {
        height: 35px;

        input {
            display: none;
        }
    }
}

.clip {
    width: 35px;
    height: 35px;
    float: right;
    cursor: pointer;
    g g {
         fill: #4d6eb7;
    }
}

.notNote {
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 18px;
}

