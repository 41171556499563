@import '../../../../resources/styles/variables.scss';
.modal {
    width: 566px;
    height: 180px;
    background-color: #fff;
	position: fixed;
	top: calc(50% - 168px);
	left: 50%;
	display: flex;
    flex-direction: column;
    align-items: center;
	transform: translate(-50%, -50%);
    outline: none;
    align-items: center;
    border-radius: 4px;
    padding: 32px;
    box-sizing: border-box;
    position: relative;
    justify-content: space-between;
}

.overlay {
    position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.22);
}

.grid {
    color: $blue;
    font-size: 18px;
}

.wrap {
    display: flex;
    width: 205px;
    justify-content: space-between;
}

.btn {
    border-radius: 5px;
    border: 1px solid $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    transition: 0.2s;
    width: 93px;
    height: 36px;
    cursor: pointer;
    font-size: 18px;
    &:hover {
        background-color: $blue;
        color: white;
    }
}