.newNote {
    font-size: 16px;
    cursor: pointer;
    color: #4d6eb7;
    margin: 12px;
}

.leftSide__noteList {
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 9px;

    &::-webkit-scrollbar {
        width: 0; 
    }

    & {
        scrollbar-width: none;
    }

    & div:last-child div:first-child p:nth-child(2) {
        border: none;
    }
}