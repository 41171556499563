@import '../../../../../../resources/styles/variables.scss';

.cardWrapper{
    width: calc(100% - 16px);
    margin: 0px 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    pointer-events: none;
}
.column{
    &_canDrop{
        .step{
            background: #f5f8fb;
        }
    }
    &_isOver{
        .step{
            background: red;
        }
    }
}
.step {
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 2px;
    position: relative;
    user-select: none;
    width: 268px;
    overflow-y: scroll;
    &:hover {
        .counter {
            opacity: 1;
        }
    }
    &__dropTarget{
        background: transparent;
        height: 100%;
        position: absolute;
        width: 100%;
    }
}

.head {
    height: 45px;
    position: relative;
    width: 100%;
    display: flex;
    background-color: $lightBlue;
    text-transform: uppercase;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.counter {
    color: #83a4cb;
    user-select: none;
    opacity: 0;
    width: 26px;
}

.name {
    font-family: Circe;
}

.icon {
    width: 26px;
    height: 26px;
}

.img {
    display: none;
    &_active {
        display: flex;
    }
}

.wrap {
    overflow: scroll;
    // height: calc(100vh - 140px);
}

.flag {
    position: absolute;
    width: 5px;
    height: 50px;
    border-radius: 5px 0 0 5px;
    background-color: #77b8d9;
    top: 0;
    left: 0;
}

.title {
    position: sticky;
    z-index: 2;
    font-size: 14px;
}

.number {
    position: sticky;
    z-index: 2;
    font-size: 14px;
}