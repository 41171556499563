.grid {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.wrap {
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.img {
    width: 24px;
    cursor: pointer;
    padding: 10px;
    transition: 0.2s;
    &:hover {
        transform: scale(1.1);
    }
}

.history {
    width: 24px;
    height: 24px;
    cursor: pointer;
    padding: 10px;
    transition: 0.2s;
    &:hover {
        transform: scale(1.1);
    }
}