@import '../../resources/styles/variables.scss';
.grid {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    width: 100%;
    transition: transform .3s ease-in 0s;
    background-color: #fff;
    height: calc(100vh - 100px);
}

.filters {
    width: 320px;
    position: relative;
    height: 100%;
    float: left;
    border-right: 1px solid #c3c3c3;
    padding: 30px 20px;
    box-sizing: border-box;
    user-select: none;
    transition: 0.4s;

    &__collapsed {
        width: 34px;
        padding: 0;

        .all, .groups, .tags {
            display: none;
        }

        
    }
}

.collapse {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bfbfbf;
    bottom: 15px;
    left: 20px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    cursor: pointer;

    &__hidden {
        display: none;
    }

    &:hover {
        background-color: #a6a6a6;
    }

    &__arrow {
        transform: rotate(270deg) scale(0.9);
        height: 14px;
    }

    svg {
        path {
            stroke: #ffffff;
            stroke-width: 2px;
        }
    }
}

.expand {
    width: 34px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;

    svg {
        transform: rotate(90deg);
        margin-left: 8px;
    }
}

.sidebar {
    position: relative;
    height: 100%;
    width: 328px;
    float: left;
    background-color: #f5f5f5;
    padding: 15px 22px 0;
    overflow: auto;
    border-right: 1px solid #c3c3c3;
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
}

.all {
    display: flex;
    width: 100%;
    text-decoration: none;
    color: black;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    margin-bottom: 20px;
    animation: fadeIn 0s 400ms forwards;
    opacity: 0; 
    &__count {
        font-size: 12px;
        margin-right: 10px;
    }
}

.groups {
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 48px;
    animation: fadeIn 0s 400ms forwards;
    opacity: 0; 
    &__plus {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: #b7b7b7;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        &:hover {
            transform: scale(1.1);
        }
        &_img {
            width: 10px;
            height: 10px;
            path {
                fill: white;
            }

        }
        
    }
}

.tags {
    display: flex;
    width: 100%;
    flex-direction: column;
    animation: fadeIn 0s 400ms forwards;
    opacity: 0; 
    &__wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 18px;
        align-items: center;
    }

    &__colors {
        display: flex;
        flex-direction: column;
        &_hidden {
            display: none;
        }
    }
    &__arrow {
        margin-right: 8px;
        transition: 0.2s;
        &_rotate {
            transform: rotateX(180deg);
        }
    }
}

.color {
    line-height: 32px;
    padding-left: 28px;
    padding-right: 8px;
    width: calc(100% - 28px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        background-color: #f0f0f0;
    }
    &__circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.client {
    position: relative;
    padding: 3px;
    cursor: pointer;
    color: #234260;
    
    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,.5);
        border-radius: 3px;
        font-size: 14px;
        margin: 0;
        padding-left: 8px;
        color: #4a4a4a;
        background-color: #fff;
        height: 50px;
        margin-bottom: 8px;
        transition: 0.2s;
        position: relative;

        & path {
            fill: #515151;
        }

        &:hover {
            transform: scale(1.01);
        }

        &_active {
            background-color: #d8d8d8;
            img {
                opacity: 0;
            }
        }
    }

    &__tag {
        position: absolute;
        height: 50px;
        width: 3px;
        border-radius: 4px 0 0 4px;
        top: 0;
        left: 0;
    }
}

.title {
    margin-left: 10px;
}

.type {
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 13px;
    color: #b5b5b5;
}

.hidden {
    display: none;
}

@keyframes fadeIn {
    to   { opacity: 1; }
}
