@import '../../../../resources/styles/variables.scss';
.modal {
	width: 916px;
	height: 466px;
	position: fixed;
	top: 50%;
	left: 50%;
	display: flex;
    flex-direction: column;
	transform: translate(-50%, -50%);
    box-sizing: border-box;
    outline: none;
	align-items: center;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 2px 4px rgba(157,157,157,0.5);

	label {
		height: 40px;
		display: flex;
		position: relative;
		cursor: pointer;
		width: 165px;
		opacity: 0.5;
		&:after {
			display: inline-block;
			width: 18px;
			height: 18px;
			border-radius: 2px;
			border: 1px solid #000;
			position: absolute;	
			content: "";
			top: 0;
			left: -20px;
			box-sizing: border-box;
		}
		&:before {
			content: "";
			background-image: url("../../../../resources/img/gala.svg");
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			position: absolute;
			width: 12px;
			height: 12px;
			top: 9px;
			left: -11px;
			transform: translate(-50%,-50%);
			opacity: 0;
		}
	
		& svg {
			path {
				fill: #000000;
			}
		}
	}
}

.overlay {
    position: fixed;
	top: 54px;
    right: 0;
    bottom: 0;
    left: 350px;
	z-index: 1000;
}

.head {
	height: 48px;
	background-color: #F5F5F5;
	display: flex;
	width: 100%;
	align-items: center;
	padding-left: 46px;
	box-sizing: border-box;
	justify-content: space-between;
	position: relative;
	border-radius: 6px 6px 0 0;
	&__tab {
		width: 72px;
		position: absolute;
		bottom: 0;
		right: 288px;
		height: 50px;
		border-radius: 10px 10px 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		
		& img {
			width: 20px;
			height: 20px;
		}
		&:nth-child(4){
			right: 216px;
			svg {
				width: 23px;
				height: 20px;
				path {
					fill: #204481;
				}
			}
		}
		&:nth-child(5){
			right: 144px;
		}
		&:nth-child(6){
			right: 73px; 
			svg {
				height: 18px;
				width: 17px;
				path {
					fill: #204481;
				}
			}
		}
		&:nth-child(7){
			right: 0;
		}

		svg {
			&:hover {
				transform: scale(1.2);
			}
		}
	}
}

.circle {
	position: absolute;
	top: 15px;
	left: 17px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border: 2px solid transparent;
	&_tag {
		width: 12px;
		height: 12px;
		border-radius: 50%;
	}
}

.activeTab {
	background-color: #fff;
	box-shadow: 0px -1px 2px 0px rgba(168, 168, 168, 0.37);

	svg {
		transform: scale(1.2);
	}
}

.wrap {
	display: flex;
	width: 100%;
	padding: 40px 0 64px 56px;
	box-sizing: border-box;
	justify-content: space-between;
}

.container {
	display: flex;
	flex-direction: column;
	flex: auto;
	width: 615px;
	max-height: 310px;
	overflow: scroll;
}

.line {
	//height: 40px;
	display: flex;
	margin-bottom: 15px;
	&__name {
		width: 132px;
		display: flex;
		font-size: 14px;
		line-height: 40px;
	}
	&__wrap {
		width: 678px;
		display: flex;
		justify-content: space-between;
	}
	&:nth-child(3){
		margin-bottom: 0;
	}
}

.dropdown {
	position: relative;
	pointer-events: none;
	&_active{ 
		pointer-events: all;
	}
	&__container {
		display: none;
		&_active {
			display: flex;
		}
	}
	&__name {
		line-height: 41px;
		width: 115px;
		height: 40px;
		border-radius: 7px;
		background-color: #F5F5F5;
		padding-left: 19px;
		box-sizing: border-box;
		cursor: pointer;
	}
	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
	}
	&__wrap {
		width: 154px;
		height: 200px;
		background-color: #fff;
		box-shadow: $shadow;
		position: absolute;
		z-index: 1000;
		border-radius: 6px;
		top: 36px;
		left: 0;
	}
	&__line {
		height: 40px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		box-sizing: border-box;
		cursor: pointer;
		&:hover {
			background-color: #F5F5F5;
		}
	}
}
.fakeArea {
	font-size: 14px;
	padding-left: 18px;
	padding-top: 10px;
	width: 530px;
	font-family: Museo500;
	border: 1px solid #DADADA;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	min-height: 40px;
	line-height: 20px;
	opacity: 0;
}

.inputName {
	box-sizing: border-box;
	height: 40px;
	border-radius: 7px;
	border: none;
	margin-right: 13px;
	font-size: 14px;
	padding-left: 18px;
	padding-top: 10px;
	width: 530px;
	font-family: Museo500;
	resize: none;
	outline: none;
	line-height: 20px;
	max-height: 94px;
	&:focus {
		border: 1px solid $blue;
	}
	&_active {
		border: 1px solid #DADADA;
	}
}

.phones {
	display: flex;
	margin-bottom: 15px;
	align-items: center;
}

.phoneWrap {
	position: relative;
	display: flex;
	flex-direction: column;
}

.inputPhone {
	box-sizing: border-box;
	height: 40px;
	border-radius: 7px;
	border: none;
	font-size: 14px;
	padding-left: 18px;
	outline: none;
	width: 522px;
	&:focus{
		border: 1px solid $blue;
	}
	&_active {
		border: 1px solid #DADADA;
	}
}

.input {
	box-sizing: border-box;
	height: 40px;
	border-radius: 7px;
	border: 1px solid #DADADA;
	margin-right: 13px;
	font-size: 14px;
	padding-left: 18px;
	width: 159px;
	&_phone {
		width: 317px;
	}
	&_phoneShort {
		width: 83px;
	}

	&_mail {
		width: 458px;
	}
	&:last-child{
		margin-right: 0;
	}
}

.mail {
	box-sizing: border-box;
	height: 40px;
	border-radius: 7px;
	border: none;
	margin-right: 13px;
	font-size: 14px;
	padding-left: 18px;
	width: 666px;
	outline: none;
	margin-bottom: 15px;
	&:focus{
		border: 1px solid $blue;
	}
	&_active {
		border: 1px solid #DADADA;
	}
	&_error {
		border: 1px solid red;
	}
	& + .btn {
		right: -32px;
		bottom: 20px;
	}
}

.tip {
	margin: 0 13px;
}

.btn {
	width: 29px;
	height: 29px;
	border-radius: 50%;
	border: 1px solid #DADADA;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left: 13px;
	position: absolute;
    right: -45px;
    bottom: 21px;

	& svg {
		height: 11px;
		width: 11px;
		& path {
			fill: #B4B4B4;
		}
	}
}

.foot {
	width: 100%;
	height: 38px;
	background-color: #F5F5F5;
	position: absolute;
	bottom: 0;
	left: 0;
	border-radius: 0 0 6px 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	&__btn {
		color: $blue;
		cursor: pointer;
		padding: 7px 27px;

		&_del {
			padding: 7px 27px;
			color: red;
			cursor: pointer;
		}
	}
}

.colors {
	height: 100%;
	margin-left: 15px;
	display: flex;

	&__wrap {
		display: flex;
		height: 100%;
		align-items: center;
		margin-right: 20px;
		cursor: pointer;

		&:hover {
			svg {
				transform: rotate(25deg);
				path {
					fill: #1b1b1b;
				}
			}
		}
	}

	&__list {
		display: flex;
	}
}

.color {
	line-height: 32px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	border-radius: 4px;
	
	&:hover {
			background-color: #f0f0f0;
	}
	&__circle {
			width: 17px;
			margin-right: 20px;
			height: 17px;
			border-radius: 50%;
			opacity: 1;
			transition: opacity 200ms ease-in, margin 200ms ease-in, width 200ms ease-in 100ms;	

			&:hover {
				transform: scale(1.05);
			}

			&_hidden {
				width: 0;
				opacity: 0;
				margin: 0;
			}
	}
}

.group {
	display: flex;
	&__name {
		width: 115px;
		font-size: 14px;
	}

	&__box {
		display: flex;
		flex-direction: column;
	}

	&__line {
		display: flex;
		width: 185px;
		justify-content: flex-end;
		font-size: 14px;

		input[type=checkbox] {
			display: none;
		}
	}

	&__loginName {
		width: 115px;
		font-size: 14px;
		height: 40px;
		display: flex;
		align-items: center;
	}

	&__login {
		width: 300px;
		height: 40px;
		border-radius: 6px;
		background-color: #F5F5F5;
		display: flex;
		align-items: center;
		padding: 0 15px;
	}
}

.userImg {
	width: 19px;
	height: 17px;
	margin: 0 20px;
}
input[type=checkbox]:checked + label {
	opacity: 1;
}
input[type=checkbox]:checked + label:before {
	opacity: 1;
}

.accessBtn {
	font-size: 13px;
	color: $blue;
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-left: 40px;
}

//////////////////-----------------------------

.employees {
	display: flex;
	width: 100%;
	flex-direction: column;
	padding-right: 56px;
	box-sizing: border-box;
	&__wrap {
		height: 40px;
		display: flex;
		align-items: center;
		padding-left: 16px;
		position: relative;
		&:hover {
			background-color: #F5F5F5;
			.card {
				display: flex;
			}
		}
	}
	&__name {
		cursor: pointer;
		width: 100%;
	    display: flex;
	}
}

.card {
	width: 397px;
	min-height: 227px;
	position: absolute;
	top: 18px;
	flex-direction: column;
	right: 0;
	box-shadow: 0 0 8px 1px #b9b9b985;
	border-radius: 4px;
	display: none;
	z-index: 100;
	background-color: #fff;
	&__head {
		min-height: 76px;
		background-color: #F5F5F5;
		width: 100%;
		padding: 16px 38px;
		box-sizing: border-box;
		border-radius: 4px 4px 0 0;
		&_active {
			background-color: #fff;
			border-bottom: 1px solid #ECECEC;
		}
	}
	&__wrap {
		padding: 16px 40px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
	}

	&__function {
		font-size: 14px;
		margin-top: 5px;
		height: 22px;
		width: 298px;
		background-color: transparent;
		outline: none;
		border: none;
		box-sizing: border-box;
		padding-left: 5px;
		border-radius: 4px;
		&_active {
			border: 1px solid #D0D8E8;
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		position: relative;

		& > .btn {
			bottom: 5px;
			right: -40px;
		}
	}

	&__line {
		display: flex;
		align-items: center;
	}

	&__input {
		margin-left: 15px;
		height: 32px;
		border: none;
		font-size: 16px;
		outline: none;
		border-radius: 4px;
		padding-left: 8px;
		box-sizing: border-box;
		margin-bottom: 5px;
		width: 260px;
		background-color: #fff;
		&_active {
			border: 1px solid #D0D8E8;
		}
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		padding: 0 20px;
		box-sizing: border-box;
		height: 36px;
		align-items: center;
	}

	&__visible {
		display: block;
		position: absolute;
		top: 60px;
		left: 20px;
	}

	&__btn {
		color: #4d6eb7;
		cursor: pointer;

		&_change {
			margin-left: 80%;
			color: #4d6eb7;
			cursor: pointer;
		}
	}
}

/////////////////-------------------------------------

.note {
	display: flex;
	align-items: center;
	margin: 7px 0;
	&__title {
		width: 178px;
		font-size: 14px;
	}
	&__input {
		height: 40px;
		border-radius: 4px;
		width: 630px;
		padding: 0 20px;
		box-sizing: border-box;
		border: none;
		outline: none;
		background-color: #f5f5f5;
		&_active {
			background-color: #fff;
			border: 1px solid #D0D8E8;
		}

		&:invalid {
			border-color: red;
		}
	}
}

.icons {
	display: flex;
	padding: 0 10px;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 70px;
		flex: 1 0 250px;
	}
}

.order {
	display: flex;
	margin: 10px 0;
	padding: 0 10px;
	font-size: 14px;

	&__description {
		box-sizing: border-box;
		display: flex;
		flex: 1 0 250px;
		white-space: nowrap;
		//background-color: #f2f5fe;
		padding: 10px 15px;
		border-radius: 2px;
		box-shadow: 0px 1px 2px rgba(75,75,75,0.5);

		&:hover {
			transform: scale(1.03);
		}
	}

	&__title {
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1 0 150px;
	}

	&__id {
		flex: 2 0 50px;
		text-align: center;
	}

	&__date {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 0 250px;
	}
}

.connections {
	display: flex;
	flex-direction: column;
	padding: 10px;

	&__title {
		font-size: 12px;
		margin-bottom: 12px;
		padding-left: 17px;
	}
	

	&__display {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		overflow: scroll;
		box-sizing: border-box;
		background-color: #f9f9f9;
		border: 1px dashed #acacac;
		border-radius: 7px;
		height: 190px;
		padding-bottom: 25px;
	}

	&__note {
		position: absolute;
		text-align: center;
		width: 100%;
		font-size: 14px;
		color: #9b9b9b;
		bottom: 15px;
		z-index: 2;
	}

	&__link {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;



		svg {
			overflow: visible;
			transform: scale(7);
			path {
				fill: #ededed;
				stroke: #ededed;
			}
		}
	}

	&__group {
		position: relative;
		display: flex;
		align-items: center;
		flex: 1 0 60px;
		font-size: 14px;

		&_description {
			padding: 10px 15px 10px 0;
		}
	}

	&__dropdown {
		display: flex;
		flex: 1 0 auto;
		justify-content: space-between;
		padding: 10px 15px;
		border-radius: 7px;
		background-color: #f9f9f9;
		color: #9b9b9b;
		cursor: pointer;

		svg {
			margin-top: 5px;
		}

		&_active {
			svg {
				transform: rotate(180deg);
			}
		}
	}
}

.list {
	font-size: 14px;
	width: 232px;
	position: absolute;
	display: none;
	right: 30px;
	top: 75%;
	z-index: 4;
	cursor: pointer;
	

	&_visible {
		display: block;
	}

	&__groups {
		background-color: #ffffff;
		box-shadow: 0px 2px 13px 0px rgba(75, 75, 75, 0.5);
		border-radius: 4px;
	}

	&__item {
		padding: 10px;

		&:hover {
			background-color: #f5f5f5;
		}

		&:first-child {
			color: #9b9b9b;
		}
	}

	&__add {
		color: #868686;
		border-radius: 4px;
		background-color: #ffffff;
		padding: 10px;
		margin-top: 10px;
		box-shadow: 0px 2px 13px 0px rgba(75, 75, 75, 0.5);
	}
}

.connection {
	position: relative;
	box-sizing: border-box;
	width: 242px;
	height: 44px;
	padding: 3px 15px;
	margin: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
	font-size: 12px;
	color: #000;
	transition: 200ms;
	z-index: 3;
	cursor: pointer;

	&:hover {
		transform: scale(1.02);
		color: #fff;

		.connection__type {
			color: #fff;
		}

		.connection__delete {
			opacity: 1;
		}
	}

	&__type {
		color: #b5b5b5;
		margin-bottom: 10px;
	}

	&__delete {
		width: calc(100% - 30px);
		opacity: 0;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 200ms;
		&:before, &:after {
			position: absolute;
			content: ' ';
			height: 15px;
			width: 1px;
			background-color: red;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
}