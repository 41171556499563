@import '../../../../resources/styles/variables.scss';
.modal {
	width: 560px;
	height: 466px;
	position: fixed;
	top: 50%;
	left: 50%;
	display: flex;
    flex-direction: column;
	transform: translate(-50%, -50%);
    box-sizing: border-box;
    outline: none;
	align-items: center;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 2px 4px rgba(157,157,157,0.5);
}

.overlay {
    position: fixed;
	top: 54px;
    right: 0;
    bottom: 0;
    left: 350px;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.22);
}

.head {
	height: 45px;
	background-color: #F5F5F5;
	display: flex;
	width: 100%;
	align-items: center;
	padding-left: 50px;
	box-sizing: border-box;
	justify-content: space-between;
	position: relative;
	border-radius: 6px 6px 0 0;
	margin-bottom: 50px;
}

.title {
	width: 100%;
    padding: 0px 50px;
    box-sizing: border-box;
    margin-bottom: 4px;
    display: flex;
}

.wrap {
	height: 40px;
	width: 438px;
	border-radius: 7px;
	border: 1px solid #9b9b9b;
	padding: 0 10px;
	margin-bottom: 50px;
}

.container {
	position: relative;
}

.input {
	height: 40px;
	width: 438px;
	border-radius: 7px;
	border: 1px solid #9b9b9b;
	padding: 0 10px;
	outline: none;
	margin-bottom: 15px;
}

.done {
	position: absolute;
	top: 12px;
    right: 12px;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	background-color: #4b795a;
	display: none;
	&_active {
		display: flex;
	}
}


.footer {
	position: absolute;
	right: 0;
	bottom: 0;
	height: 40px;
	background-color: #F5F5F5;
	display: flex;
	width: 100%;
	align-items: center;
	box-sizing: border-box;
	justify-content: flex-end;
	border-radius: 0 0 6px 6px;
	padding-right: 23px;
}

.btn {
	cursor: pointer;
	transition: 0.1s;
	&:hover {
		color: $blue;
		transform: scale(1.05);
	}
}