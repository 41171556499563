.grid {
    overflow: hidden;
    border-radius: 3px;
    width: 100%;
    transition: transform .3s ease-in 0s;
    background-color: #fff;
    height: 100%;
}

.addBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    margin-right: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 13px;
    left: 90px;
    &:hover {
        background-color: #fff;
    }

    &__icon {
        width: 13px;
        height: 12px;
        & path {
            fill: white;
        }
    }
}

.sidebar {
    position: relative;
    height: 100%;
    width: 328px;
    float: left;
    background-color: #f5f5f5;
    padding: 15px 22px 0;
    border-right: 1px solid #c3c3c3;
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
}

.board {
    position: relative;
    padding: 3px;
    cursor: pointer;
    color: #234260;
    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,.5);
        border-radius: 3px;
        font-size: 14px;
        margin: 0;
        padding-left: 17px;
        padding-right: 11px;
        color: #4a4a4a;
        text-transform: uppercase;
        background-color: #fff;
        height: 40px;
        margin-bottom: 6px;
        position: relative;
        & path {
            fill: #515151;
        }
    }
}

.wrap {
    position: relative;
    &:hover {
        .card {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.title {
    margin-left: 20px;
}

.card {
    display: flex;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 15px;
    left: 240px;
    flex-direction: column;
    z-index: 1;
    align-items: flex-end;
    transition: 0.1s;
    &__wrap {
        border-radius: 4px;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        background-image: linear-gradient(180deg,#4d6bb2,#4d74c0 4%,#4d78c6 9%,#4c76c4 17%,#4a74c0 23%,#335496 60%,#00285e);
        align-items: center;
        box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.2);
        justify-content: center;
        width: 575px;
        color: white;
        height: 325px;
        &:hover {
            .card__container {
                transform: scale(1.08);
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        transition: 0.1s;
    }
    &__img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        path {
            fill: white;
        }
    }
}



.remove {
    color: #FF0000;
    font-size: 16px;
    margin-top: 13px;
    margin-bottom: 15px;
    transition: 0.1s;
    cursor: pointer;
    font-family: Circe;
    &:hover {
        transform: scale(1.05);
    }
}