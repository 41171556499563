.grid {
    position: relative;
}

.foto {
    border-radius: 50%;
    object-fit: cover;
    &_dissable {
        background-color: #d8d8d8;
    }
}

.btnLabel {
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease-in;
    background-color: #12407d45;
    &:hover {
        background-color: #12407d80;
    }
    &__img {
        width: 24px;
        height: 24px;
    }
    
}

.photoInput {
    opacity: 0;
    width: 0;
    height: 0;
}
