.cardWrapper{
    width: calc(100% - 16px);
    margin: 0px 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    transform: translate(0, 0);
    &_dragging .card{
        display: none;
        // height: 0;
        // opacity: 0;
    }
    &_hidden{
        display: none;
    }
}
.card {
    position: relative;
    height: 50px;
    padding: 3px 15px;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    box-shadow: 1px 1px 2px 0 hsla(0,0%,66%,.78);
    align-items: center;
    justify-content: space-between;
    &:hover {
        transform: scale(1.01);
    }
    &_over{
        // background: #000;
        // .card__bcg{
        //     background: #000;
        // }
    }
    &__bcg {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: #899fcc;
        opacity: 0.4;
        z-index: 1;
        border-radius: 5px;
    }
}