.modal {
  position: fixed;
  width: 560px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.22);
  z-index: 1001;
}

.head {
  padding: 10px 22px;
  border-radius: 5px 5px 0 0;
  background-color: #f5f5f5;

  &__input {
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    background-color: #fff;
    padding: 10px;
    font-size: 15px;
    outline: none;
  }
}

.description {
  height: 350px;
  padding: 10px 22px;
  font-size: 14px;

  &__title {
    padding: 0 10px;
    margin-bottom: 5px;
  }

  &__text {
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    font-size: 14px;
    color: #868686;
    resize: none;
    width: 100%;
    height: auto;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
  }
}

.foot {
  font-size: 14px;
  color: #4d73be;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 0 22px;
  background-color: #f5f5f5;
  border-radius: 0 0 5px 5px;

  &__btn {
    cursor: pointer;
  }
}