
.noteList__wrapper {
    display: flex;
    flex-wrap: nowrap;
    height: 45px;
    width: 297px;

    &__cross {
        transform: translateX(-76px);
        transition: transform .5s ease-out;
    }

    &__cancelDelete {
        transform: translateX(76px);
        transition: transform .5s ease-out;
    }
}

.noteList__delete {
    background-color: red;
    height: 100%;
    width: 70px;
    color: white;
    line-height: 45px;
    padding: 0 7px;
    cursor: pointer;
}

.noteList__item {
    // border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
    position: relative;
    width: 100%;

    &:hover {
        background: #fcf6da;
        span {
            display: block;
        }
    }

    .active {
        border: 1px solid red;
    }
    
    & p {
        margin: 3px 0;
        margin-left: 14px;
        
    }

    & p:first-child {
        font-size: 14px;
        color: #555;
    }

    & p:nth-child(2) {
        color: #b4b4b4;
        font-size: 13px;
        border-bottom: 1px solid #dfdfdf;
        padding-bottom: 6px;
    }

    &__active {// переделать, так как происходит дублироавние кода
        background: #faedb4;
        cursor: pointer;
        position: relative;
    
        & p {
            margin: 3px 0;
            margin-left: 14px;
        }

        & p:first-child {
            font-size: 14px;
            color: #555;
        }

        & p:nth-child(2) {
            color: #b4b4b4;
            font-size: 13px;
            padding-bottom: 6px;
        }

        &:hover {
            background: #fcf6da;
            span {
                display: block;
            }
        }
    }
}

.newNote {
    color: #4d6eb7;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 10px;
}

span {
    position: absolute;
    top: 10px;
    right: 17px;
    display: none;

    &:hover {
        color: red;
    }
}
