.btn {
    border: 2px solid #fff;
    min-width: 226px;
    width: 290px;
    height: 56px;
    line-height: 56px;
    padding: 0 15px;
    color: #fff;
    background: none;
    outline: none;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    transition: border-color .28s cubic-bezier(.4,0,.2,1) 0s,color .28s cubic-bezier(.4,0,.2,1) 0s;
    margin-top: 40px;
}