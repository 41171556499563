@import '../../../../resources/styles/variables.scss';
.modal {
	width: 916px;
	height: 466px;
	position: fixed;
	top: 50%;
	left: 50%;
	display: flex;
    flex-direction: column;
	transform: translate(-50%, -50%);
    box-sizing: border-box;
    outline: none;
	align-items: center;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 2px 4px rgba(157,157,157,0.5);
}

.module {
	height: 40px;
	display: flex;
	position: relative;
	cursor: pointer;
	width: 165px;
	opacity: 0.5;
	&:after {
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 2px;
		border: 1px solid #000;
		position: absolute;	
		content: "";
		top: 0;
		left: -20px;
		box-sizing: border-box;
	}
	&:before {
		content: "";
		background-image: url("../../../../resources/img/gala.svg");
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		position: absolute;
		width: 12px;
		height: 12px;
		top: 9px;
		left: -11px;
		transform: translate(-50%,-50%);
		opacity: 0;
	}

	& svg {
		path {
			fill: #000000;
		}
	}
}

.overlay {
    position: fixed;
	top: 54px;
    right: 0;
    bottom: 0;
    left: 350px;
	z-index: 1000;
}

.head {
	height: 48px;
	background-color: #F5F5F5;
	display: flex;
	width: 100%;
	align-items: center;
	padding-left: 46px;
	box-sizing: border-box;
	justify-content: space-between;
	position: relative;
	border-radius: 6px 6px 0 0;
}

.userTab {
		width: 102px;
		position: absolute;
		bottom: 0;
		right: 103px;
		height: 50px;
		border-radius: 10px 10px 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&_active {
			background-color: #fff;
			box-shadow: 0px -1px 2px 0px rgba(168, 168, 168, 0.37)
		}

		& img {
			width: 20px;
			height: 20px;
		}
}

.keyTab {
		width: 102px;
		position: absolute;
		bottom: 0;
		right: 0;
		height: 50px;
		border-radius: 10px 10px 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&_active {
			background-color: #fff;
			box-shadow: 0px -1px 2px 0px rgba(168, 168, 168, 0.37)
		}
		& img {
			width: 20px;
			height: 20px;
		}
}

.wrap {
	display: flex;
	width: 100%;
	padding: 56px 38px 64px;
	box-sizing: border-box;
	justify-content: space-between;
}

.foto {
	margin-right: 38px;
	height: 185px;
	border-radius: 50%;
	margin-right: 38px;
}

.container {
	display: flex;
	flex-direction: column;
	flex: auto;
	width: 615px;    
	overflow: scroll;
    height: 293px;
	
}

.name {
	width: 115px;
	display: flex;
	align-items: center;
	font-size: 14px;
}

.line {
	display: flex;
	margin-bottom: 16px;
	align-items: center;
	&__name {
		width: 115px;
		display: flex;
		font-size: 14px;
		flex-shrink: 0;
		align-self: flex-start;
		padding-top: 11px;
		box-sizing: border-box;
	}
	&__container {
		display: flex;
		flex-direction: column;
	}
	&__wrap {
		width: 497px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
	}
	&__wrapper {
		width: 457px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.input {
	box-sizing: border-box;
	height: 40px;
	border-radius: 7px;
	border: 1px solid #DADADA;
	margin-right: 13px;
	font-size: 14px;
	padding-left: 18px;
	width: 159px;
	&_phone {
		width: 317px;
	}
	&_phoneShort {
		width: 83px;
	}

	&_mail {
		width: 458px;
	}
	&:last-child{
		margin-right: 0;
	}
}

.tip {
	margin: 0 13px;
}

.btn {
	width: 29px;
	height: 29px;
	border-radius: 50%;
	border: 1px solid #DADADA;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 13px;
	align-self: flex-end;
	margin-bottom: 5px;
	pointer-events: none;
	&_active {
		pointer-events: all;
		cursor: pointer;
	}
	& svg {
		height: 11px;
		width: 11px;
		& path {
			fill: #B4B4B4;
		}
	}
}

.foot {
	width: 100%;
	height: 38px;
	background-color: #F5F5F5;
	position: absolute;
	bottom: 0;
	left: 0;
	border-radius: 0 0 6px 6px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	box-sizing: border-box;
	&__btn {
		color: #a4a4a4;
		cursor: pointer;
		padding: 7px 27px;
		transition: 0.1s;
		&:hover {
			color: $blue;
			transform: scale(1.05);
		}
	}

	svg {
		path {
			fill: $blue;
		}
	}
}

.remove {
	position: absolute;
    top: 6px;
	left: 20px;
	cursor: pointer;
}

.group {
	display: flex;
	margin-bottom: 10px;
	&__name {
		width: 115px;
		font-size: 14px;
	}

	&__box {
		display: flex;
		flex-direction: column;
	}

	&__line {
		display: flex;
		width: 185px;
		justify-content: flex-end;
		font-size: 14px;

		input[type=checkbox] {
			display: none;
		}
	}

	&__loginName {
		width: 115px;
		font-size: 14px;
		height: 40px;
		display: flex;
		align-items: center;
	}

	&__login {
		width: 300px;
		height: 40px;
		border-radius: 6px;
		background-color: #F5F5F5;
		display: flex;
		align-items: center;
		padding: 0 15px;
	}
}



.userImg {
	width: 19px;
	height: 17px;
	margin: 0 20px;
}
input[type=checkbox]:checked + label {
	opacity: 1;
}
input[type=checkbox]:checked + label:before {
	opacity: 1;
}

.accessBtn {
	font-size: 13px;
	color: $blue;
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-left: 40px;
}

