@import '../../../../../../../../resources/styles/variables.scss';
.modal {
    width: 592px;
    height: 500px;
    background-color: #fff;
	position: fixed;
	top: calc(50% + 47px);
	left: 50%;
	display: flex;
    flex-direction: column;
	transform: translate(-50%, -50%);
    outline: none;
    border-radius: 6px;
    padding: 20px 40px;
    box-sizing: border-box;
    position: relative;
}

.overlay {
    position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
}

.grid {
    display: flex;
    margin-bottom: 10px;
}

.date {
    width: 127px;
    font-size: 16px;
    font-family: Circe;
    letter-spacing: 0.5px;
}

.notes {
    display: flex;
    font-size: 14px;
    font-family: Circe;
    margin: 5px 0;
}

.note {
    width: 128px;
}

.name {
    width: 128px;
}