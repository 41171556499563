@import '../../../../../../resources/styles/variables.scss';
.grid {
    height: 500px;
    background-color: #fff;
    border-radius: 6px;
    padding: 25px 20px;
    box-sizing: border-box;
    position: relative;
}

.wrap {
    overflow: scroll;
    height: 395px;
}

.title {
    font-size: 18px;
    color: $blue;
    margin-bottom: 22px;
}

.priсe {
    position: absolute;
    right: 20px;
    top: 28px;
    font-size: 16px;
    color: $blue;
    font-family: Circe;
}

.line {
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.name {
    width: 130px;
    font-size: 14px;
}

.input {
    background-color: #F5F5F5;
    height: 36px;
    border: none;
    border-radius: 2px;
    flex: 1;
    padding-left: 12px;
    box-sizing: border-box;
    &:nth-child(3){
        margin-left: 10px;
    }
}

.date {
    margin: 0 10px;
}

.foot {
    height: 36px;
    background-color: #F5F5F5;
    border-radius: 0 0 6px 6px;
    display: flex;
    margin: 0px -20px;
    justify-content: space-between;
}

.btn {
    color: $blue;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 20px;

    &__text {
        margin-left: 10px;
    }
}

.datepicker{
    width: 422px;
    height: 36px;
    background-color: rgb(245, 245, 245);
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 7px;
    border: none;
    padding-left: 18px;
    &_active {
        border: 1px solid #DADADA;
    }
}