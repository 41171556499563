@import '../../../../../../resources/styles/variables.scss';
.grid {
    padding: 14px 68px 10px 36px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.wrap {
    overflow-x: scroll;
    box-sizing: border-box;
    white-space: nowrap;
}

.card {
    position: relative;
    display: inline-flex;
    &_disactive {
        opacity: 0;
        pointer-events: none;
    }
    .stepPlaceholder{
        display: block;
        min-width: 150px;
        height: calc(100vh - 95px);
        width: 268px;
        margin-right: 10px;
    }
}

.overlay {
    position: fixed;
    top: 55px;
    left: 351px;
    width: calc(100% - 351px);
    height: calc(100% - 55px);
    display: none;
    &_active {
        display: block;
        z-index: 1;
    }
}

.step {
    height: calc(100vh - 95px);
    transform: translate(0, 0);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    user-select: none;
    width: 268px;
    overflow-y: scroll;
    border: 1px solid #d2d2d2;
    margin-right: 10px;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
        background-color: #F6F9FB;
        .container .left{
            display: block;
        }
    }
    &_dragged{
        opacity: 0.3;
    }
    &_active {
        z-index: 2;
    }
}

.head {
    height: 45px;
    width: 100%;
    display: flex;
    background-color: $lightBlue;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    &__empty {
        width: 26px;
    }
}
.name {
    font-family: Circe;
    text-transform: uppercase;
    border: none;
    text-align: center;
    outline: none;
    font-size: 18px;
    width: 160px;
    &:disabled {
        background: #e0e8f3;
    }

}

.icon {
    width: 26px;
    height: 26px;
}

.img {
    display: none;
    cursor: pointer;
    &_active {
        display: flex;
    }
}

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 140px);
    padding: 30px 10px;
    box-sizing: border-box;
    &:hover {
        .container__wrap {
            display: flex;
        }
    }
    &__wrap {
        display: none;
        justify-content: space-between;
        width: 100%;
    }
}

.positions {
    display: flex;
    width: 100%;
    justify-content: space-around;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s;
    &_active {
        opacity: 1;
        pointer-events: all;
    }
    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__img {
        width: 34px;
        height: 34px;
        margin-bottom: 15px;
    }

    &__title {
        text-transform: uppercase;
        font-size: 14px;
        font-family: Circe;
    }
} 

.left {
    display: none;
}

.remove {
    color: #ff4040;
    font-family: Circe;
    font-size: 18px;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s;
    width: 224px;
    height: 44px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_active {
        opacity: 1;
        pointer-events: all;
    }
    &:hover {
        background-color: #ff4040;
        color: white;
    }
}

.addBtn {
    position: absolute;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid $blue;
    cursor: pointer;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
     svg {
         width: 18px;
         height: 18px;
         path {
             fill: $blue;
         }
     }
     &:hover {
         background-color: $blue;
         svg {
             path {
                 fill: white;
             }
         }
     }
}

.modal {
   width: 600px;
   height: 195px;
   border-radius: 6px; 
   position: fixed;
   transform: translate(-50%, -50%);
   top: 50%;
   left: 50%;
   background-color: #fff;
   padding: 22px 25px;
   box-sizing: border-box;
   outline: none;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-around;
   &__overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.22);
        z-index: 1000;
   }
   &__title {
        font-size: 20px;
        color: #385a9e;
        font-family: Circe;

       span {
           text-transform: uppercase;
       }
   }

   &__wrap {
       display: flex;
       justify-content: space-between;
       width: 240px;
   }
   &__btn {
       width: 90px;
       height: 40px;
       border: 1px solid $blue;
       border-radius: 4px;
       display: flex;
       color: $blue;
       cursor: pointer;
       align-items: center;
       justify-content: center;
       transition: 0.2s;
       &:hover {
           background-color: $blue;
           color: white
       }
   }
}