@import '../../../../resources/styles/variables.scss';
.wrap {
    box-sizing: border-box;
	height: 40px;
	border-radius: 7px;
	border: 1px solid #DADADA;
    font-size: 14px;
    padding-left: 18px;
    color: #000000;
    &:focus {
        border: 1px solid $blue;
        outline: none;
    }
}

.disabled {
    border: none;
}