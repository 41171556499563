@import '../../../../resources/styles/variables.scss';
.grid {
    display: flex;
    overflow: scroll;
    margin-right: -23px;
}

.wrap {
    overflow: scroll;
    height: calc(100vh - 140px);
}

.step {
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 2px;
    position: relative;
    user-select: none;
    width: 268px;
    overflow-y: scroll;
    &:hover {
        .counter {
            opacity: 1;
        }
    }
}

.head {
    height: 45px;
    width: 100%;
    display: flex;
    background-color: $lightBlue;
    text-transform: uppercase;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
}

.counter {
    color: #83a4cb;
    user-select: none;
    opacity: 0;
    width: 26px;
}

.name {
    font-family: Circe;
}

.icon {
    width: 26px;
    height: 26px;
}

.img {
    display: none;
    &_active {
        display: flex;
    }
}

.card {
    position: relative;
    width: calc(100% - 16px);
    height: 50px;
    padding: 3px 15px;
    margin: 8px;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    box-shadow: 1px 1px 2px 0 hsla(0,0%,66%,.78);
    align-items: center;
    justify-content: space-between;
    &:hover {
        transform: scale(1.01);
    }
    &__bcg {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: #fff;
        opacity: 0.4;
        z-index: 1;
        border-radius: 5px;
    }
}

.flag {
    position: absolute;
    width: 5px;
    height: 50px;
    border-radius: 5px 0 0 5px;
    background-color: #77b8d9;
    top: 0;
    left: 0;
}

.title {
    position: sticky;
    z-index: 2;
    font-size: 14px;
}

.number {
    position: sticky;
    z-index: 2;
    font-size: 14px;
}