@import '../../../../../../resources/styles/variables.scss';
.grid {
    padding: 14px 68px 10px 36px;
    box-sizing: border-box;
    overflow: hidden;
}

.wrap {
    overflow-x: scroll;
    display: flex;
}

.card {
    position: relative;
}

.overlay {
    position: fixed;
    top: 55px;
    left: 351px;
    width: calc(100% - 351px);
    height: calc(100% - 55px);
    display: none;
    &_active {
        display: block;
        z-index: 1;
    }
}

.step {
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 2px;
    position: relative;
    user-select: none;
    width: 268px;
    overflow-y: scroll;
    border: 1px solid #d2d2d2;
    margin-right: 10px;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
        background-color: #F6F9FB;
        .container .left{
            display: block;
        }
    }
    &_active {
        z-index: 2;
    }
}

.head {
    height: 45px;
    width: 100%;
    display: flex;
    background-color: $lightBlue;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    &__empty {
        width: 26px;
    }
}

.name {
    font-family: Circe;
    text-transform: uppercase;
    border: none;
    text-align: center;
    outline: none;
    font-size: 18px;
    width: 173px;
}

.icon {
    width: 26px;
    height: 26px;
}

.img {
    display: none;
    &_active {
        display: flex;
    }
}

.container {
    height: calc(100vh - 140px);
    padding: 30px 10px 30px 20px;
    box-sizing: border-box;
    &:hover {
        .container__wrap {
            display: flex;
        }
    }
    &__wrap {
        display: none;
        justify-content: space-between;
        width: 100%;
    }
}

.positions {
    display: flex;
    width: 100%;
    justify-content: space-around;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s;
    &_active {
        opacity: 1;
        pointer-events: all;
    }
    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__img {
        width: 34px;
        height: 34px;
        margin-bottom: 15px;
    }

    &__title {
        text-transform: uppercase;
        font-size: 14px;
        font-family: Circe;
    }
} 


.user {
    display: flex;
    margin-bottom: 20px;
    opacity: 0.5;
    &__name {
        font-family: Circe;
        margin-left: 20px;
    }
    &_active {
        opacity: 1;
    }
}

.account {
    height: 28px;
    margin-bottom: 20px;
    display: flex;
}

.avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.done {
    width: 19px;
    height: 15px;
    margin-right: 9px;
}