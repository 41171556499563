@import '../../../../../../resources/styles/variables.scss';
.grid {
    height: 500px;
    background-color: #fff;
    border-radius: 6px;
    padding-top: 25px;
    box-sizing: border-box;
    position: relative;
}

.wrap {
    overflow: scroll;
    height: 395px;
    padding: 0 20px;
}

.title {
    font-size: 18px;
    color: $blue;
    margin-bottom: 22px;
    margin-left: 20px;
}

.priсe {
    position: absolute;
    right: 20px;
    top: 28px;
    font-size: 16px;
    color: $blue;
    font-family: Circe;
}

.line {
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.name {
    width: 160px;
    font-size: 14px;
}

.input {
    //background-color: #fafafa;
    height: 40px;
    border: none;
    border-radius: 7px;
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 12px;
    font-size: 14px;
    box-sizing: border-box;
    &:nth-child(3){
        margin-left: 10px;
    }
    &__wrap {
        margin-left: 10px;
        display: flex;
    }
}

.date {
    margin: 0 10px;
}

.btn {
    height: 36px;
    border-top: 1px solid #DFDFDF;
    background-color: #F5F5F5;
    border-radius: 0 0 6px 6px;
    position: relative;
    &__title {
        position: absolute;
        color: $blue;
        top: 10px;
        right: 20px;
        cursor: pointer;
    }
}