.grid {
	position: relative;
	height: 36px;
	pointer-events: none;
	max-width: 422px;
	&_active {
		pointer-events: all;
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	display: none;
	&_active {
		display: block;
	}
}

.value {
	z-index: 10001;
	width: 422px;
	cursor: pointer;
	line-height: 36px;
    height: 36px;
    background-color: rgb(245, 245, 245);
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 7px;
    border: none;
    padding-left: 18px;
	position: absolute;
	&_acitve{
		border: 1px solid #DADADA;
	}
}

.list {
	width: 420px;
    background-color: white;
	box-shadow: 0 0 4px 0 #b9b9b968;
	position: absolute;
	top: 36px;
	box-sizing: border-box;
	left: 0;
	margin: 0;
	border-radius: 7px;
	z-index: 10001;
	padding-left: 0;
	display: none;
	&_active{
		display: block;
	}
}

.item {
	height: 36px;
	line-height: 36px;
	padding-left: 20px;
	cursor: pointer;
	&:hover {
		background-color: rgb(245, 245, 245);
	}
}

.arrow {
	position: absolute;
    left: 388px;
    top: 16px;
    transition: 0.2s;
    z-index: 100001;
	transform: rotate(180deg);
	&_active {
		transform: rotate(0);
	}
}